import s from './About.module.scss';
import { Header } from './Components/Header/Header';
import { ReactComponent as ArrowDown } from 'assets/images/ArrowDown.svg';
import { ReactComponent as ContactsBg } from 'assets/images/ContactsBg.svg';
import ExpertiseImg from 'assets/images/Expertise.png';
import InnovationsImg from 'assets/images/Innovations.png';
import { ReactComponent as LogoBg } from 'assets/images/LogoBg.svg';
import { ReactComponent as LogoBg2 } from 'assets/images/LogoBg2.svg';
import UserOrientationImg from 'assets/images/UserOrientation.png';

const cards = [
  {
    image: ExpertiseImg,
    title: 'Профессионализм',
    description:
      'Мы предоставляем точные и надежные данные, основанные на астрологических принципах ',
  },
  {
    image: InnovationsImg,
    title: 'Инновации',
    description:
      'Мы постоянно развиваемся и внедряем новые астрологические методики, чтобы улучшить опыт наших пользователей',
  },
  {
    image: UserOrientationImg,
    title: 'Пользовательская ориентация',
    description:
      'Мы всегда ставим потребности наших пользователей на первое место и стремимся предложить им интуитивно понятные астрологические решения',
  },
];

const AboutPage = () => (
  <div className={s.page}>
    <Header />
    <LogoBg className={s.bg1} />
    <div className={s.page_title}>
      <p className={s.title} style={{ width: 1061 }}>
        Мы - команда профессионалов, стоящая за мобильным приложением «Финансовый
        гороскоп»
      </p>
      <a className={s.slide} href="#mission">
        <ArrowDown />
        <p style={{ maxWidth: 425 }}>
          Узнайте больше о том, кто мы такие, что нас вдохновляет, и как мы можем помочь
          вам в управлении вашими финансами, используя астрологию.
        </p>
      </a>
    </div>
    <LogoBg2 className={s.bg2} />
    <div className={s.mission}>
      <div id="mission" className={s.mission_image} />
      <div className={s.mission_text}>
        <h2 className={s.text_title}>НАША МИССИЯ</h2>
        <p style={{ maxWidth: 684 }}>
          Cделать финансовое планирование доступным и увлекательным для всех, используя
          астрологические принципы. Мы стремимся помочь нашим пользователям принимать
          информированные финансовые решения, предоставляя им персонализированные прогнозы
          и советы, основанные на их знаке зодиака и астрологическом гороскопе
        </p>
      </div>
    </div>
    <div className={s.values}>
      <p className={s.title} style={{ maxWidth: 1008, marginBottom: 60 }}>
        Мы основываемся на следующих ценностях
      </p>
      <div className={s.cards}>
        {cards.map(card => (
          <div key={card.title} className={s.card}>
            <img src={card.image} alt={card.title} />
            <div className={s.card_text}>
              <p className={s.card_title}>{card.title}</p>
              <p className={s.card_description}>{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className={s.approach}>
      <p className={s.title} style={{ maxWidth: 900 }}>
        Наш подход к астрологии и финансам
      </p>
      <div className={s.approach_description}>
        <div />
        <p>
          Сделать финансовое планирование доступным и увлекательным для всех, используя
          астрологические принципы. <br />
          <br /> Мы стремимся помочь нашим пользователям принимать информированные
          финансовые решения, предоставляя им персонализированные прогнозы и советы,
          основанные на их знаке зодиака и астрологическом гороскопе
        </p>
      </div>
    </div>
    <div className={s.team}>
      <div className={s.team_text}>
        <p className={s.title} style={{ maxWidth: 361 }}>
          Наша команда
        </p>
        <p className={s.team_description} style={{ maxWidth: 750 }}>
          Мы гордимся нашей командой экспертов в области астрологии, разработки приложений
          и финансов, которые работают над созданием «Финансового гороскопа» Наши
          специалисты обладают богатым опытом и страстью к тому, что делают. <br /> <br />{' '}
          Наши специалисты обладают богатым опытом и страстью к тому, что делают.
        </p>
      </div>
      <div className={s.team_image} />
    </div>
    <div id="mission" className={s.contacts_image} />
    <div className={s.contacts} id="contacts">
      <p className={s.title} style={{ maxWidth: 900 }}>
        Контакты
      </p>
      <div className={s.contacts_description}>
        <p style={{ maxWidth: 551 }}>
          Мы всегда рады обратной связи от наших пользователей! Если у вас есть вопросы,
          предложения или отзывы, пожалуйста, свяжитесь с нами по адресу электронной почты{' '}
          <a href="mailto: support@astrofin.ru">support@astrofin.ru</a>
        </p>
        <ContactsBg className={s.contacts_bg} />
      </div>
    </div>
  </div>
);

export default AboutPage;
