import React from 'react';
import { Outlet } from 'react-router-dom';
import Background from '../../UI/Background/Background';
import Navbar from '../../UI/Navbar/Navbar';
import styles from './FeedbackLayout.module.scss';

const FeedbackLayout = () => (
  <>
    <Background>
      <Navbar />
      <div className={styles.appContainer}>
        <Outlet />
      </div>
    </Background>
  </>
);

export default FeedbackLayout;
