import styles from './App.module.scss';
import Router from 'navigation/Router';
import {envConsoleWarn} from 'utils/envConsoleWarn';
import './styles/main.scss';
import FeedbackLayout from "./components/Layout/FeedbackLayout/FeedbackLayout";
import {Suspense} from 'react'

const App = () => {
    envConsoleWarn();

    return (
        <div className={styles.app}>
            <Suspense fallback={<FeedbackLayout/>}>
                <Router/>
            </Suspense>
        </div>
    );
};

export default App;
