import { Link, useLocation } from 'react-router-dom';
import { NAV_ROUTES } from '../../../navigation';
import styles from './Navbar.module.scss';
import { cn } from 'utils/classNames';

const Navbar = () => {
  const { pathname } = useLocation();

  const isActive = (routeName: string): boolean => {
    if (!pathname.includes(routeName)) return false;
    return true;
  };

  return (
    <nav className={styles.navbar}>
      <ul className={styles.navbar_list}>
        {NAV_ROUTES.map(route => (
          <li key={route.href} className={styles.navbar_node}>
            <Link
              className={cn(isActive(route.href) && styles.active, styles.navbar_link)}
              to={route.href}
            >
              {route.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
