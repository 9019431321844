import React, { forwardRef } from 'react';
import styles from './Button.module.scss';

type ButtonProps = {
  onClick?: () => void;
  onSubmit?: () => void;
  text?: string;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick, onSubmit, children, text }: ButtonProps, ref) => (
    <button
      ref={ref}
      type="button"
      className={styles.Button}
      onClick={onClick}
      onSubmit={onSubmit}
    >
      {children}
    </button>
  ),
);

export default Button;
