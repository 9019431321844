import React from 'react';
import { Outlet } from 'react-router-dom';

const LandingLayout = () => (
  <>
    <Outlet />
  </>
);

export default LandingLayout;
