import Button from '../../../../components/UI/Button/Button';
import s from './Header.module.scss';
import { ReactComponent as LogoIcon } from 'assets/icons/Logo.svg';

export const Header = () => (
  <header className={s.header}>
    <div className={s.container}>
      <div className={s.logo}>
        <LogoIcon />
        <p className={s.logo_title}>
          Финансовый <br /> гороскоп
        </p>
      </div>
      <div className={s.action}>
          <a style={{ color: 'white', fontSize: 12, height: '100%', display: 'block' }} href="#contacts">
        <Button style={{ width: 110, maxHeight: '100%', padding: 0 }}>
            КОНТАКТЫ
        </Button>
          </a>
      </div>
    </div>
  </header>
);
