import React from 'react';
import {Route, Routes} from 'react-router-dom';
import LandingLayout from '../components/Layout/LandingLayout/LandingLayout';
import {COMMON_ROUTES} from './index';
import FeedbackLayout from 'components/Layout/FeedbackLayout/FeedbackLayout';
import AboutPage from "../pages/About/About";

const SupportPage = React.lazy(() => import('../pages/Support/Support'));
const LicensePage = React.lazy(() => import('../pages/License/License'));
const PoliticsPage = React.lazy(() => import('../pages/Politics/Politics'));
const PersonalAgreementPage = React.lazy(
    () => import('../pages/PersonalAgreement/PersonalAgreement'),
);
const UserAgreementPage = React.lazy(
    () => import('../pages/UserAgreement/UserAgreement'),
);
const AuthorizationAgreementPage = React.lazy(
    () => import('../pages/AuthorizationAgreement/AuthorizationAgreement'),
);

const Router = () => (
    <Routes>
        <Route element={<FeedbackLayout/>}>
            <Route path={COMMON_ROUTES.SUPPORT} element={<SupportPage/>}/>
            <Route path={COMMON_ROUTES.LICENSE} element={<LicensePage/>}/>
            <Route path={COMMON_ROUTES.POLITICS} element={<PoliticsPage/>}/>
            <Route path={COMMON_ROUTES.USER_AGREEMENT} element={<UserAgreementPage/>}/>
            <Route
                path={COMMON_ROUTES.PERSONAL_AGREEMENT}
                element={<PersonalAgreementPage/>}
            />
            <Route
                path={COMMON_ROUTES.AUTHORIZATION_AGREEMENT}
                element={<AuthorizationAgreementPage/>}
            />
        </Route>
        <Route path="/" element={<LandingLayout/>}>
            <Route path={COMMON_ROUTES.ABOUT} element={<AboutPage/>}/>
        </Route>
    </Routes>
);

export default Router;
