const { NODE_ENV, REACT_APP_MODE } = process.env;

const getVariableValue = (MODE, name) =>
  process.env[`${name}_${MODE}`] || process.env[name];

const IS_PRODUCTION = NODE_ENV === 'production';

const MODE = REACT_APP_MODE || (IS_PRODUCTION ? 'PROD' : undefined);

const PROXY_BASE_URL = getVariableValue(MODE, 'REACT_APP_BASE_URL');

/** НЕ МЕНЯТЬ РАСШИРЕНИЕ С .js И НЕ УДАЛЯТЬ module.exports */
module.exports.Config = {
  NODE_ENV,
  IS_PRODUCTION,
  MODE,
  PROXY_BASE_URL,
};
