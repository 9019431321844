import { Config } from '../constants/env';

export const envConsoleWarn = () => {
  if (Config.IS_PRODUCTION) return;

  if (!Config.MODE) {
    // eslint-disable-next-line no-console
    return console.log(
      `%cПроверьте переменные в .env!
    MODE: ${Config.MODE},
    PROXY_BASE_URL: ${Config.PROXY_BASE_URL}`,
      `display: inline-block;
     padding: 10px; 
     background: red;
     color: black`,
    );
  }

  // eslint-disable-next-line no-console
  console.log(
    `%cРежим ${Config.MODE}!!! Все запросы идут на ${Config.PROXY_BASE_URL}`,
    `display: inline-block;
     padding: 20px; 
     background: ${Config.MODE === 'PROD' ? 'red' : 'green'}; 
     color: black`,
  );
};
