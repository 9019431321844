import styles from './Background.module.scss';
import { ChildrenComponent } from 'utils/types';

const Background = ({ children }: ChildrenComponent) => (
  <>
    <pre className={styles.background} />
    <div className={styles.content}>{children}</div>
  </>
);

export default Background;
